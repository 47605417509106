@import "~bootstrap/scss/bootstrap";

@font-face{
  font-family: 'MetaWebPro';
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-normal.eot');
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-normal.eot?#iefix') format('embedded-opentype'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-normal.woff') format('woff'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metacomppro-normal.ttf') format('truetype'),url('https://www.uni-muenster.de/imperia/md/images/allgemein/farbunabhaengig/fonts/metacomppro-normal.svg#metacomppro-normal') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family: 'MetaWebPro';
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-normalita.eot');
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-normalita.eot?#iefix') format('embedded-opentype'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-normalita.woff') format('woff'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metacomppro-normalita.ttf') format('truetype'),url('https://www.uni-muenster.de/imperia/md/images/allgemein/farbunabhaengig/fonts/metacomppro-normalita.svg#metacomppro-normalita') format('svg');
  font-weight: 400;
  font-style: italic;
}
@font-face{
  font-family: 'MetaWebPro';
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-bold.eot');
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-bold.eot?#iefix') format('embedded-opentype'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-bold.woff') format('woff'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metacomppro-bold.ttf') format('truetype'),url('https://www.uni-muenster.de/imperia/md/images/allgemein/farbunabhaengig/fonts/metacomppro-bold.svg#metacomppro-bold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face{
  font-family: 'MetaWebPro';
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-boldita.eot');
  src:url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-boldita.eot?#iefix') format('embedded-opentype'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metawebpro-boldita.woff') format('woff'),url('https://www.uni-muenster.de/imperia/md/content/allgemein/farbunabhaengig/fonts/metacomppro-boldita.ttf') format('truetype'),url('https://www.uni-muenster.de/imperia/md/images/allgemein/farbunabhaengig/fonts/metacomppro-boldita.svg#metacomppro-boldita') format('svg');
  font-weight: 700;
  font-style: italic;
}
  
header {
  margin: 1.6%;
}

footer {
  margin-left: 1.6%;
  margin-right: 1.6%;
}

#logos {
  padding-bottom: 1em;
  border-bottom: 4px solid #000;
  margin-bottom: 2%;
}

.wrapper {
  max-width: 70em;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-weight: 700;
  font-size: 200%;
}

h2,.form-label {
  font-weight: 600;
  font-size: 140%;
}

body {
  font-family: MetaWebPro,Verdana,sans-serif;
  font-size: 88%;
}

.topnavbar {
  width: 100%;
}

.language-selector {
  float: right;
  font-size: 1.15em;
}

button {
  color: #000000;
  background: none;
  border: none;
  padding: 0;
}
button:hover {
  color: #000000;
  text-decoration: underline;
}
button:focus {
  outline: 0px;
}

.content {
  border: 2px solid #bec6c8;
  font-size: 1.1em;
  line-height: 1.4;
  margin-left: 1.6%;
  margin-right: 1.6%;
  padding-bottom: 1.6%;
  border-top-width: 25px;
  border-top-style: solid;
  border-top-color: #7ab51d;
}

.policy-markdown {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1.6%;
}

.btn-primary {
  background-color: #000000;
  border-color: #000000;
  width: 100%;
}

.btn-primary:hover {
  background-color: #444444;
  border-color: #444444;
  width: 100%;
}

.btn-primary:disabled {
  opacity: 0.45;
  background-color: #000000;
  border-color: #000000;
  width: 100%;
}

.bg-light {
  background-color: #ffffff !important;
}